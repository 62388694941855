.sc-fzqBkg .sc-fzqPZZ .eDloXy{
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-fzqBkg {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .sc-fzqPZZ {
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }
  .eDloXy{
    color: #ffffff !important;
    padding-bottom: 20pt !important;
  }