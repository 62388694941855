
.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    margin: 0 auto;
    padding: 45px;
    text-align: left;
    /* left: 260px; */
    position: relative;
  }
  
  img[src="./logo_smaller.png"] {
    max-height: 150px;
  }

  @media (max-width: 767px) {
    .markdown-body {
      padding: 15px;
    }
  }
  
  #table-of-contents + ul:before{
    content: "";
    display: block;
    background: url('/logo.png') no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #ffffff; 
    height: 100px;  
    padding-top: 20pt;
    padding-bottom: 20pt;
    padding-left: 0pt;
    border-bottom: #c4c4c4 1px solid;
    margin: auto;
  
  } 
  #table-of-contents + ul {
    float: left;
    position: fixed;
    left: 0px; 
    padding-left: 0pt;
    height: 100%;
    top: 0px;
    list-style: none;
    color: #212121;
    max-width: 260px;
    background-color: #FAFAFA;
  }
  
  #table-of-contents + ul > li { 
    list-style: none;
    color: #212121;
    font-size: 10pt; 
    padding-left: 20pt;
    padding-top: 10pt;
  }
   
  
  #table-of-contents + ul > li { 
    list-style: none;
    color: #212121;
    font-size: 10pt; 
  }
  
  #table-of-contents + ul > li  * { 
    list-style: none;
    color: #282c31;
    font-size: 10pt; 
    padding-top: 5pt;
  }
   
  
  #table-of-contents {
    display: none;
  }
  
  @media (max-width: 1500px) {
    .markdown-body {
      margin: 0;
      left: 260px;
    }
  }
  
  @media (max-width: 450px) {
    .markdown-body {
      margin: 0 auto;
      left: 0px;
    }
    #table-of-contents + ul {
      display: none;
    }
  }